<template>
  <div class="church-list template-1">
    <header class="page-header container">
      <h1>{{ translations.tcMembersList }}</h1>
    </header>
    <!-- / Page Header -->
    <camp-selector @camp_changed="page_load()" :showCamp="false" :tooltip="translations.tcCurrentHome"
      :i18n="translations.components"></camp-selector>
    <!-- / Camp Selector -->
    <data-table :fields="fields" :listType="list_type" :items="list" :addItems="addItems" :toggleMe="true"
      :handleDeleteAction="false" :handleEditAction="false" :handleViewAction="false" :csvUrl="csvUrl"
      :reportName="reportName" :i18n="translations.components"></data-table>
    <!-- / Data Tables -->
    <footer class="page-footer"></footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTableMemberChurches.vue'
import { translationMixin } from '@/mixins/translationMixin'
import { stateBreadcrumbMixin } from '@/mixins/breadcrumbMixinFactory'

export default {
  name: 'members-list',
  mixins: [translationMixin, stateBreadcrumbMixin],
  data() {
    return {
      translations: {},
      list_type: 'camp_member_churches',
      fields: [
        { key: 'sort_name', label: 'Name', sortable: true },
        { key: 'cmp_number', label: 'Camp Number', sortable: true },
        { key: 'org_name', label: 'Camp Name', sortable: true },
      ],
      hiddenItems: {
        display: false,
      },
      addItems: {
        display: false,
        text: 'Add Speaker', // Add Visit
        action: 'addPresenter',
      },
      reportName: 'StateMemberListReport',
    }
  },
  methods: {
    ...mapActions({
      getMemberChurches: 'campModule/getMemberChurches',
      getMembersList: 'directory/getMembersList',
      loadChurches: 'churchMinistry/loadChurches',
      loadStateMemberListReportUrls: 'directory/loadStateMemberListReportUrls',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        let stateKey = !!this.officerSelectStatekey.country_state_name
          ? this.officerSelectStatekey.country_state
          : this.userStateKey
        await this.getMembersList(stateKey)
        await this.loadStateMemberListReportUrls(stateKey)
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    loadTranslations() {
      const { tcCampName, tcCampNumber, tcName } = this.translations.components['data-table-member-churches']
      this.fields[0].label = tcName
      this.fields[1].label = tcCampNumber
      this.fields[2].label = tcCampName

      this.addItems.text = this.translations.tcAddMember
    },
  },
  async created() {
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('camp-select', 'data-table', 'data-table-member-churches'),
    ]).then((results) => {
      const componentTranslations = results[1]
      this.$set(this.translations, 'components', componentTranslations)
      this.loadTranslations()
    })
  },
  computed: {
    ...mapGetters({
      csvUrl: 'directory/stateMemberListReportCSV',
      held_visits_by_camp: 'churchMinistry/held_visits_by_camp',
      list: 'directory/membersList',
      officerSelectStatekey: 'user/officerToolbarSelected',
      pdfUrl: 'directory/stateMemberListReportPDF',
      prefCulture: 'user/userPreferredCulture',
      userCampKey: 'user/userCampKey',
      userId: 'user/userId',
      userOrgKey: 'user/userOrgKey',
      userStateKey: 'user/userStateKey',
    }),
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';

.cwidth {
  min-width: 252px !important;
}
</style>
